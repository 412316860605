.order-modal {
    // background: #fff;
    border-radius: 12px;
    // padding: 30px 50px;
    position: relative;
    width: 600px;
    .order {
        background: #fff;
        border-radius: 12px;
        padding: 30px 50px;
        @media (max-width: 576px) {
            padding: 15px;
        }
    }
    h6 {
        font-size: 22px;
        margin-bottom: 40px;
        color: #252523;
    }
    .modal-close {
        right: 30px;
        top: 30px;
    }
    .steps {
        display: flex;
        justify-content: space-between;
        margin-top: 30px;
        button {
            height: 38px;
            border: 1px solid #ec1b33;
            color: #ec1b33;
            background: transparent;
            width: 160px;
            font-size: 14px;
            border-radius: 6px;
            transition: 0.3s;
            &:hover {
                background: #ec1b33;
                color: #fff;
            }
            &.btn-prev {
                border-color: rgba(0, 0, 0, 0.4);
                color: rgba(0, 0, 0, 0.4);
                &:hover {
                    border-color: rgba(0, 0, 0, 0.4);
                    background: rgba(0, 0, 0, 0.4);
                    color: #fff;
                }
            }
        }
    }
    @media (max-width: 500px) {
        padding: 15px;
        width: calc(100% - 40px);
        .modal-close {
            top: 30px;
            right: 30px;
        }
        .steps {
            button {
                width: 120px;
                font-size: 12px;
            }
        }
    }
}

.form-group {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    position: relative;
    label {
        font-size: 14px;
        line-height: 16px;
        color: rgba(0, 0, 0, 0.3);
        position: absolute;
        background: #fff;
        transform: translateY(-50%);
        left: 15px;
        padding: 0 5px;
        transition: 0.3s;
    }
    button {
        position: absolute;
        background: none;
        padding: 0;
        border: none;
        color: #ec1b33;
        text-decoration: underline;
        top: 50%;
        transform: translateY(-50%);
        right: 20px;
        &:hover {
            text-decoration: none;
        }
    }
    input {
        height: 44px;
        border: 1px solid #e0e0e0;
        background-color: #fff;
        border-radius: 5px;
        padding: 0 100px 0 15px;
        font-size: 14px;
        outline: none !important;
        transition: 0.3s;
        &:focus,
        &:active {
            border-color: #ec1b33 !important;
            &+label {
                color: #ec1b33;
                transition: 0.3s;
            }
        }
    }
}

input {
    -webkit-box-shadow: inset 0 0 0 50px #fff;
    -webkit-text-fill-color: #000;
}

.radio-button__input {
    display: none;
}

.radio-button__label {
    user-select: none;
    position: relative;
    cursor: pointer;
    width: 100%;
    font-size: 16px;
    line-height: 21px;
    display: grid;
    grid-template-columns: 16px 1fr 1fr;
    column-gap: 10px;
    margin-bottom: 0;
    grid-template-rows: 16px;
    &_with_description {
        grid-template-areas: "radio__mark radio__title radio__pictures";
        grid-template-rows: auto auto;
        // row-gap: 10px;
        span.radio-button__mark {
            grid-area: radio__mark;
        }
        .radio-button__title {
            grid-area: radio__title;
        }
        .radio-button__description {
            grid-area: radio__description;
        }
    }
    div {
        grid-area: radio__pictures;
        display: flex;
        justify-content: flex-end;
        img {
            margin-left: 15px;
            height: 18px;
        }
    }
    @media (max-width: 500px) {
        grid-template-areas: "radio__mark radio__title" "radio__mark radio__pictures";
        grid-template-columns: 16px 1fr;
        align-items: center;
        row-gap: 3px;
        div {
            justify-content: flex-start;
            // margin-left: 30px;
            img {
                height: 12px;
                &:first-child {
                    margin-left: 0;
                }
            }
        }
    }
}

.radio-button {
    display: flex;
    align-self: center;
    margin-top: 15px;
    &_block {
        .radio-button__label_with_description {
            grid-template-areas: "radio__mark radio__title";
            grid-template-columns: 16px 1fr;
        }
    }
}

.radio-button__mark {
    min-width: 16px;
    max-width: 16px;
    min-height: 16px;
    max-height: 16px;
    display: block;
    border: 1px solid #ccc;
    position: relative;
    left: 0;
    border-radius: 50%;
}

.radio-button .radio-button__input+.radio-button__mark:before {
    content: "";
    position: absolute;
    width: 8px;
    height: 8px;
    max-width: 8px;
    max-height: 8px;
    background: #ec1b33;
    border-radius: 50%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
}

.radio-button .radio-button__input:checked+.radio-button__mark {
    border-color: #ec1b33;
    box-shadow: 0px 2px 10px rgba(#ec1b33, 0.3);
    &:before {
        opacity: 1;
    }
    &+.radio-button__title {
        color: #ec1b33;
    }
}

.radio-button__title {
    color: #252523;
    font-size: 16px;
    line-height: 17px;
}

.radio-buttons-group {
    .radio-button {
        padding-top: 10px * 1.5;
        &:last-child {
            .radio-button__description {
                border-bottom: 0;
            }
        }
    }
}

//////
.checkbox-button__input {
    display: none;
}

.checkbox-button__label {
    user-select: none;
    position: relative;
    cursor: pointer;
    width: 100%;
    font-size: 16px;
    line-height: 21px;
    display: grid;
    grid-template-columns: 16px 1fr 1fr;
    column-gap: 10px;
    margin-bottom: 0;
    grid-template-rows: 16px;
    &_with_description {
        grid-template-areas: "checkbox__mark checkbox__title checkbox__title";
        grid-template-columns: auto 1fr;
        grid-template-rows: auto auto;
        a {
            color: inherit;
            text-decoration: underline;
            &:hover {
                text-decoration: none;
            }
        }
        // row-gap: 10px;
        span.checkbox-button__mark {
            grid-area: checkbox__mark;
        }
        .checkbox-button__title {
            grid-area: checkbox__title;
        }
        .checkbox-button__description {
            grid-area: checkbox__description;
        }
    }
    div {
        grid-area: checkbox__pictures;
        display: flex;
        justify-content: flex-end;
        img {
            margin-left: 15px;
            height: 18px;
        }
    }
    @media (max-width: 500px) {
        grid-template-areas: "checkbox__mark checkbox__title" "checkbox__mark checkbox__title";
        grid-template-columns: 16px 1fr;
        align-items: center;
        row-gap: 3px;
        div {
            justify-content: flex-start;
            // margin-left: 30px;
            img {
                height: 12px;
                &:first-child {
                    margin-left: 0;
                }
            }
        }
    }
}

.checkbox-button {
    display: flex;
    align-self: center;
    margin-top: 15px;
    &_block {
        .checkbox-button__label_with_description {
            grid-template-areas: "checkbox__mark checkbox__title";
            grid-template-columns: 16px 1fr;
        }
    }
    &.error {
        .checkbox-button__label {
            p {
                color: #ec1b33 !important;
            }
        }
    }
}

.checkbox-button__mark {
    min-width: 16px;
    max-width: 16px;
    min-height: 16px;
    max-height: 16px;
    display: block;
    border: 1px solid #ccc;
    position: relative;
    left: 0;
    border-radius: 6px;
}

.checkbox-button .checkbox-button__input+.checkbox-button__mark:before {
    content: "";
    position: absolute;
    width: 4px;
    height: 16px;
    border-bottom: 2px solid #ec1b33;
    border-right: 2px solid #ec1b33;
    max-width: 8px;
    max-height: 8px;
    // background: #ec1b33;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    opacity: 0;
    margin-top: -1px;
}

.checkbox-button .checkbox-button__input:checked+.checkbox-button__mark {
    border-color: #ec1b33;
    box-shadow: 0px 2px 10px rgba(#ec1b33, 0.3);
    &:before {
        opacity: 1;
    }
    &+.checkbox-button__title {
        color: #ec1b33;
    }
}

.checkbox-button__title {
    color: #252523;
    font-size: 16px;
    line-height: 17px;
}

.checkbox-buttons-group {
    .checkbox-button {
        padding-top: 10px * 1.5;
        &:last-child {
            .checkbox-button__description {
                border-bottom: 0;
            }
        }
    }
}

.countdown {
    margin-top: 10px;
    font-size: 14px;
    color: #999;
    min-height: 14px;
}

.order__total {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    font-size: 16px;
    align-items: center;
    p:last-child {
        font-size: 18px;
        font-weight: 700;
        b {
            color: #ec1b33;
            font-size: 24px;
        }
    }
    @media (max-width: 500px) {
        flex-wrap: wrap;
        justify-content: flex-end;
        p {
            width: 100%;
            text-align: right;
            &:last-child {
                margin-top: 10px;
            }
        }
    }
}

.Toastify__toast-container {
    z-index: 999999999 !important;
}

.loader {
    position: relative;
    &:before {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        background: rgba(255, 255, 255, 0.8);
        left: 0;
        top: 0;
        z-index: 3;
    }
    &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -30px;
        margin-left: -15px;
        // transform: translate(-50%, -50%);
        width: 30px;
        height: 30px;
        border-radius: 50%;
        border: 5px solid #252523;
        border-top-color: #ec1b33;
        animation: loading 1.5s linear infinite;
        z-index: 4;
    }
    @keyframes loading {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
}

.select-block {
    padding-bottom: 10px;
    position: relative;
    .select-block__error {
        color: #ec1b33;
        position: absolute;
        bottom: 0;
        transform: translateY(50%);
        font-size: 14px;
    }
}

.addresses-list {
    padding-top: 10px;
    .radio-button {
        margin-top: 15px;
    }
    margin-bottom: 25px;
}

.order__subtitle {
    font-weight: 700;
    display: block;
}

.select-block+.order__subtitle {
    margin-top: 15px;
}

.inputs {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: flex-start;
    margin-top: 10px;
}

div[class*="-ValueContainer"] {
    padding: 9px 8px;
}

.user {
    margin-bottom: 0;
}

.counter-wrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-top: 0;
    b {
        margin-bottom: 0;
        margin-top: 0;
        font-weight: 700;
    }
    .counter {
        display: flex;
        width: auto;
        align-items: center;
        justify-content: space-around;
        padding: 0 15px;
        border: 1px solid #e60e27;
        max-height: 30px;
        min-height: 30px;
        border-radius: 6px;
        // margin-bottom: 20px;
        // margin-top: -20px;
        button {
            background: transparent;
            color: #e60e27;
            // border: 2px solid #e60e27;
            border: none;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 0;
            font-size: 20px;
            min-width: 30px;
            max-width: 30px;
            padding: 0;
            min-height: 30px;
            max-height: 30px;
            font-weight: 700;
            transition: 0.2s;
            // &:hover {
            //     background: #e60e27;
            //     color: #fff;
            // }
        }
        span {
            font-size: 20px;
            width: 50px;
            display: block;
            text-align: center;
        }
    }
    @media (max-width: 767px) {
        .counter {
            margin-top: 10px;
            margin-bottom: 10px;
        }
    }
}