main {
    // margin-top: 185px !important;
    display: flex !important;
    align-items: center;
    justify-content: space-between;
    h1 {
        font-weight: bold;
        font-size: 56px;
        line-height: 64px;
        color: #222a2e;
        margin-bottom: 37px;
    }
    @media (min-width: 768px) and (max-width: 991px) {
        h1 {
            font-size: 48px;
            line-height: 64px;
            margin-bottom: 27px;
        }
        margin-top: 140px !important;
    }
    @media (max-width: 767px) {
        h1 {
            font-size: 32px;
            line-height: 38px;
        }
    }
    .product {
        display: flex;
        justify-content: space-between;
        // position: relative;
        // z-index: 4;
        .product__banner {
            margin-top: -0px;
            display: flex;
            align-items: flex-start;
            img {
                max-height: 446px;
                height: 100%;
                margin-right: 100px;
            }
            .product__view {
                display: none;
            }
            @media (max-width: 576px) {
                pointer-events: none;
            }
        }
        @media (min-width: 991px) and (max-width: 1200px) {
            .product__banner {
                img {
                    margin-right: 80px;
                }
            }
        }
        @media (min-width: 768px) and (max-width: 991px) {
            .product__banner {
                position: relative;
                img {
                    max-height: 340px;
                    margin-right: 0;
                }
            }
        }
        @media (max-width: 767px) {
            .product__banner {
                margin-top: 75px;
                margin-left: -90px;
                position: relative;
                img {
                    max-height: 200px;
                    margin-right: 0;
                }
                .product__view {
                    border-radius: 50%;
                    background: #fff;
                    border: none;
                    padding: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 58px;
                    position: absolute;
                    width: 58px;
                    z-index: 40;
                    left: 0;
                    margin-left: -20px;
                    top: 50px;
                    box-shadow: 0px 4px 38px -12px rgba(0, 0, 0, 0.18), 0px 8px 96px -12px rgba(34, 42, 46, 0.32);
                    img {
                        width: 28px;
                    }
                }
            }
        }
    }
    .product__params {
        display: inline-grid;
        column-gap: 25px;
        row-gap: 25px;
        grid-template-areas: "param1 param3" "param2 param4";
        grid-template-columns: auto auto;
        .product__params-item {
            display: grid;
            grid-template-columns: 42px auto;
            img {
                margin: 0 auto;
                width: 42px;
            }
            &:first-child {
                grid-area: param1;
            }
            &:nth-child(2) {
                grid-area: param2;
            }
            &:nth-child(3) {
                grid-area: param3;
            }
            &:nth-child(4) {
                grid-area: param4;
            }
            div {
                margin-left: 18px;
                span,
                b {
                    display: block;
                }
                b {
                    font-weight: bold;
                    font-size: 20px;
                    line-height: 20px;
                    color: #222a2e;
                    margin-bottom: 3px;
                }
                span {
                    font-size: 16px;
                    line-height: 20px;
                    color: #8699a3;
                    max-width: 130px;
                }
            }
        }
        @media (min-width: 768px) and (max-width: 991px) {
            .product__params-item {
                div {
                    b {
                        font-size: 18px;
                        line-height: 20px;
                    }
                    span {
                        font-size: 14px;
                        line-height: 20px;
                    }
                }
            }
        }
        @media (max-width: 767px) {
            display: block;
            .product__params-item {
                margin-bottom: 9px;
                grid-template-columns: 32px auto;
                column-gap: 10px;
                align-items: center;
                img {
                    max-height: 32px;
                    height: 100%;
                }
                &:last-child {
                    margin-bottom: 0;
                }
                div {
                    margin-left: 0;
                    b {
                        font-size: 16px;
                        line-height: 20px;
                        margin-bottom: 1px;
                    }
                    span {
                        font-size: 14px;
                        line-height: 20px;
                        text-transform: capitalize;
                    }
                }
            }
        }
    }
    .product__footer {
        // display: flex;
        // align-items: center;
        display: grid;
        grid-template-columns: 185px 1fr;
        column-gap: 24px;
        margin-top: 87px;
        button {
            background: #e60e27;
            border-radius: 6px;
            height: 64px;
            border: none;
            color: #fff;
            font-size: 18px;
            line-height: 22px;
            padding: 0 36px;
            // margin-right: 24px;
        }
        div {
            span {
                font-size: 16px;
                line-height: 20px;
                color: #8699a3;
                margin-bottom: 3px;
                @media (max-width: 576px) {
                    white-space: nowrap;
                }
                b {
                    font-size: 20px;
                    // color: #e60e27;
                    text-decoration: line-through;
                }
            }
            h2 {
                font-size: 42px;
                font-weight: 400;
                line-height: 40px;
                color: #e60e27;
                text-transform: uppercase;
            }
        }
        @media (min-width: 768px) and (max-width: 991px) {
            div {
                span {
                    font-size: 16px;
                    line-height: 20px;
                }
                h2 {
                    font-size: 36px;
                    line-height: 40px;
                }
            }
        }
        @media (max-width: 767px) {
            margin-top: 44px;
            overflow-y: hidden;
            button {
                height: 56px;
                font-size: 18px;
                line-height: 22px;
                margin-right: 14px;
            }
            div {
                span {
                    font-size: 14px;
                    line-height: 20px;
                }
                h2 {
                    font-size: 32px;
                    line-height: 40px;
                }
            }
        }
        @media (max-width: 420px) {
            grid-template-columns: auto auto;
            button {
                font-size: 14px;
                padding: 0px;
                width: 104px;
                height: 60px;
                margin-right: 0;
            }
            .counter {
                button {
                    max-width: 20px;
                    min-width: 20px;
                    font-size: 18px;
                }
                span {
                    font-size: 18px;
                    width: 30px;
                }
            }
        }
    }
}

.footer {
    padding: 44px 0px;
    position: relative;
    .footer__doc {
        a {
            color: #8699a3;
            text-decoration: underline;
            &:hover {
                text-decoration: none;
            }
        }
    }
    .footer__inner {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        span {
            font-size: 16px;
            line-height: 20px;
            color: #8699a3;
            margin-right: 18px;
        }
    }
    @media (max-width: 991px) {
        display: none;
    }
}

* {
    scroll-behavior: smooth;
}

// body,
// #root,
// html
.page {
    @media (max-width: 576px) {
        overflow-y: hidden !important;
    }
}

.page {
    display: grid;
    min-height: 100vh;
    height: 100vh;
    overflow: hidden;
    grid-template-rows: auto 1fr auto;
    position: relative;
    @media (max-width: 991px) {
        display: grid;
        grid-template-rows: auto auto 1fr;
        overflow-y: scroll;
    }
    @media (max-width: 576px) {
        height: 100%;
        min-height: 100vh;
    }
    @media (max-width: 767px) {
        display: block;
    }
    @media (max-width: 767px) and (min-height: 720px) {
        display: grid;
        grid-template-rows: auto 1fr 320px;
    }
    // @media (max-width: 767px) and (min-height: 900px) {
    //     overflow-y: hidden;
    // }
    // @media screen and (max-width: 576px) {
    //     min-height: -webkit-fill-available;
    // }
}

.circle {
    width: 70vw;
    height: 90vh;
    position: absolute;
    background-image: url("../../assets/icons/yellow-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50%;
    bottom: -15%;
    pointer-events: none;
    right: -15%;
    z-index: -1;
    .container {
        position: relative;
        display: flex;
        justify-content: flex-end;
    }
    @media (min-width: 1800px) {
        width: 1200px;
        left: 50%;
        height: 1200px;
        max-height: 90vh;
        bottom: -15%;
    }
    @media (min-width: 991px) and (max-width: 1200px) {
        bottom: -20%;
        width: 100vw;
        right: -40%;
    }
    @media (max-width: 991px) {
        display: none;
    }
}

.product__button {
    position: absolute;
    height: 90vh;
    bottom: -15%;
    width: 100%;
    .container {
        position: relative;
        display: flex;
        justify-content: flex-end;
    }
    .product__view {
        border-radius: 50%;
        background: #fff;
        border: none;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        // position: absolute;
        // right: 0;
        height: 84px;
        width: 84px;
        margin-left: 22px;
        transform: translateY(-50%);
        z-index: 10;
        box-shadow: 0px 4px 38px -12px rgba(0, 0, 0, 0.18), 0px 8px 96px -12px rgba(34, 42, 46, 0.32);
        img {
            width: 37px;
        }
    }
    @media (min-width: 1800px) {
        .woman-block {
            padding-left: 20px;
            padding-right: 20px;
            max-width: 1120px;
            margin: 0 auto;
            position: absolute;
            bottom: 0;
            width: 100%;
            left: 0;
            right: 0;
            height: 100vh;
            // pointer-events: none;
            .woman_right {
                right: -200px;
            }
            .woman_left {
                left: 300px;
            }
        }
    }
    @media (min-width: 991px) and (max-width: 1200px) {
        bottom: -20;
        height: 80vh;
        .product__view {
            transform: translateY(-100%);
        }
    }
    @media (min-width: 768px) and (max-width: 991px) {
        top: 150px;
        width: 100%;
        .container {
            position: relative;
            .product__view {
                margin-right: 250px;
                margin-top: 125px;
                transform: none;
            }
        }
    }
    @media (max-width: 767px) {
        bottom: unset;
        top: 50px;
        left: 0;
        .container {
            position: relative;
            display: block;
        }
        .product__view {
            height: 58px;
            position: absolute;
            top: 175px;
            width: 58px;
            margin-left: 0;
            right: 175px;
            img {
                width: 28px;
            }
        }
    }
    @media (max-width: 767px) {
        display: none;
    }
    .woman {
        // pointer-events: none;
        &_left {
            max-height: 480px;
            position: absolute;
            bottom: 15%;
            left: 35%;
            z-index: 4;
        }
        &_right {
            max-height: 373px;
            position: absolute;
            bottom: 15%;
            right: -50px;
            z-index: 4;
        }
        @media (min-width: 991px) and (max-width: 1200px) {
            &_left {
                max-height: 300px;
            }
            &_right {
                max-height: 300px;
            }
        }
        @media (max-width: 991px) {
            display: none;
        }
        // @media (min-width: 768px) and (max-width: 991px) {
        //     display: none;
        // }
    }
}

.mobile-circle {
    position: relative;
    // height: 350px;
    width: 100%;
    display: block;
    margin-bottom: -50px;
    margin-top: 100px;
    &:before {
        content: "";
        width: 125%;
        height: 100%;
        position: absolute;
        background-image: url("../../assets/icons/yellow-bg.png");
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 50% 50% 0 0;
        // bottom: -100%;
        // bottom: -60%;
        left: 50%;
        transform: translateX(-50%);
        pointer-events: none;
        // right: 50%;
        // transform: translateX(-50%);
        z-index: -1;
    }
    @media (min-width: 991px) {
        display: none;
    }
    // @media (min-width: 768px) and (max-width: 991px) and (max-height: 940px) {
    //     height: 350px;
    // }
    @media (max-width: 767px) {
        height: 320px !important;
        max-height: 300px;
        margin-top: 50px;
        position: relative;
        bottom: 0;
        margin-bottom: 0;
    }
    // @media (max-width: 767px) and (min-height: 900px) {
    //     position: absolute;
    // }
    @media (min-width: 768px) and (max-width: 991px) {
        height: 350px;
        .woman_left {
            max-height: 400px;
            left: 0;
        }
        .woman_right {
            max-height: 300px;
            right: 0;
        }
        .woman {
            position: absolute;
            bottom: 0;
        }
    }
    @media (max-width: 767px) {
        // height: 270px;
        margin-top: 30px;
        .woman {
            position: absolute;
            bottom: 0;
        }
        .woman_left {
            max-height: 266px;
            margin-left: -30px;
        }
        .woman_right {
            max-height: 200px;
            right: -30px;
        }
    }
}

header {
    @media (min-width: 320px) and (max-width: 991px) {
        width: calc(100% - 40px);
    }
}

button {
    cursor: pointer;
}

.ReactModal__Overlay {
    z-index: 999999;
    background: rgba(0, 0, 0, 0.5) !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.carousel-modal {
    max-width: 1000px;
    margin: 0 auto;
    max-height: 800px;
    width: 100%;
    height: 100%;
    position: relative;
    background: transparent !important;
    // top: 50% !important;
    // transform: translateY(-50%);
    display: flex;
    border: none !important;
    align-items: center;
    к &:before {
        content: "";
        width: 800px;
        height: 700px;
        background: #fff;
        position: absolute;
        border-radius: 6px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
    @media (max-width: 500px) {
        &.ReactModal__Content {
            max-height: 400px;
            .swiper-container {
                height: 400px;
            }
        }
    }
}

.carousel-modal {
    .swiper-slide {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 800px;
        border-radius: 6px;
        img {
            object-fit: cover;
            max-height: 600px;
            max-width: 700px;
        }
    }
    .modal-content {
        position: relative;
        // background: #fff;
        // border-radius: 6px;
    }
    .swiper-container {
        height: 800px;
    }
    .swiper-button-prev:after,
    .swiper-container-rtl .swiper-button-prev:after,
    .swiper-button-next:after,
    .swiper-container-rtl .swiper-button-next:after {
        color: #fff;
    }
    .swiper-pagination-bullet.swiper-pagination-bullet-active {
        background: #fff !important;
    }
    span.swiper-pagination-bullet {
        opacity: 1 !important;
        background: rgba(255, 255, 255, 0.65) !important;
    }
    .slide-content {
        background: #fff;
        height: 100%;
        display: flex;
        align-items: center;
        max-height: 700px;
    }
    @media (max-width: 900px) {
        width: calc(100% - 40px);
        .swiper-button-prev,
        .swiper-button-next {
            display: none;
        }
        &:before {
            width: calc(100% - 40px);
        }
        .swiper-slide.swiper-slide-active {
            width: 100% !important;
        }
        .modal-close {
            top: 75px;
            right: 50px;
        }
    }
    @media (max-width: 767px) {
        .swiper-slide img {
            max-width: 100%;
        }
    }
    @media (max-width: 500px) {
        max-height: 500px;
        &:before {
            height: 400px;
        }
        .swiper-container {
            height: 500px;
            display: flex;
            align-items: center;
        }
        .swiper-wrapper {
            align-items: center;
        }
        .slide-content {
            max-height: 400px;
        }
        .modal-close {
            top: 65px;
            right: 20px;
        }
    }
    @media (min-width: 500px) and (max-width: 640px) {
        .modal-close {
            top: 65px;
            right: 10px;
        }
    }
    @media (min-width: 640px) and (max-width: 700px) {
        .modal-close {
            top: 65px;
            right: 30px;
        }
    }
}

.product__extended {
    color: #e60e27;
    text-decoration: underline;
    margin-top: 30px;
    margin-bottom: -30px;
    cursor: pointer;
    &:hover {
        opacity: 0.8;
    }
    @media (max-width: 768px) {
        overflow-y: hidden;
    }
}

.params-modal,
.status-modal {
    background: #fff;
    border-radius: 12px;
    padding: 30px 50px;
    position: relative;
    .modal-close {
        right: 30px;
        top: 30px;
    }
}

.status-modal {
    .status {
        text-align: center;
        h4 {
            font-size: 32px;
            margin: 10px 0px 20px;
            line-height: 48px;
        }
        p {
            font-size: 22px;
            line-height: 28px;
            margin-bottom: 25px;
        }
        img {
            margin-top: 30px;
            width: 50px;
            height: 50px;
        }
    }
}

.modal-info {
    width: 700px;
    z-index: 9;
    margin: 0 auto;
    // margin-top: -400px;
    h4 {
        font-size: 32px;
        line-height: 48px;
        margin-bottom: 10px;
        text-align: center;
    }
    h5 {
        text-align: center;
        font-size: 22px;
        line-height: 32px;
        margin-bottom: 10px;
    }
    ul {
        list-style-type: none;
        li {
            padding-left: 0;
            padding-top: 10px;
            padding-bottom: 10px;
            position: relative;
            &:before {
                content: "";
                border-radius: 50%;
                width: 6px;
                height: 6px;
                background: #8699a3;
                position: absolute;
                left: -15px;
                top: 50%;
                transform: translateY(-50%);
            }
        }
        position: relative;
        &:after {
            content: "";
            width: 100%;
            height: 2px;
            z-index: 0;
            background: #8699a3;
            position: absolute;
            bottom: -45px;
        }
        &+div {
            display: flex;
            justify-content: center;
            margin-top: 30px;
            b {
                font-weight: bold;
                background: #fff;
                position: relative;
                z-index: 9;
                font-size: 24px;
                padding: 0 10px;
            }
        }
    }
    .modal-pluses {
        margin-top: 30px;
        p {
            text-align: center;
            font-size: 20px;
            line-height: 28px;
            color: #252523;
        }
    }
    .modal-pluses__content {
        margin-top: 30px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        position: relative;
        div {
            text-align: center;
            max-width: 200px;
            // background: #fff;
            font-size: 16px;
            // padding: 0 100
            line-height: 18px;
            position: relative;
            &:nth-child(2) {
                &:before {
                    content: "";
                    width: 2px;
                    height: 50px;
                    right: -15px;
                    background: #8699a3;
                    position: absolute;
                    z-index: 0;
                }
                &:after {
                    content: "";
                    width: 2px;
                    height: 50px;
                    left: -15px;
                    top: 0;
                    background: #8699a3;
                    position: absolute;
                    z-index: 0;
                }
            }
        }
    }
}

.page .counter {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0 15px;
    border: 2px solid #e60e27;
    max-height: 60px;
    min-height: 60px;
    border-radius: 6px;
    button {
        background: transparent;
        color: #e60e27;
        // border: 2px solid #e60e27;
        border: none;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 0;
        font-size: 28px;
        min-width: 40px;
        max-width: 40px;
        padding: 0;
        min-height: 40px;
        max-height: 40px;
        font-weight: 700;
        transition: 0.2s;
        // &:hover {
        //     background: #e60e27;
        //     color: #fff;
        // }
    }
    span {
        font-size: 28px;
        width: 50px;
        display: block;
        text-align: center;
    }
}

.modal-close {
    width: 18px;
    height: 18px;
    position: absolute;
    right: 135px;
    top: 85px;
    border: none;
    background: none;
    z-index: 8;
    cursor: pointer;
    span {
        width: 18px;
        height: 2px;
        background: #ec1b33;
        position: absolute;
        bottom: 6px;
        margin-left: -2px;
        &:first-child {
            left: 0;
            transform: rotate(45deg);
        }
        &:last-child {
            transform: rotate(-45deg);
            left: 0;
        }
    }
}

.params-modal {
    @media (max-width: 900px) {
        width: calc(100% - 130px);
        .modal-info {
            width: 100%;
            .modal-pluses__content div {
                &:nth-child(2):before {
                    display: none;
                }
                &:nth-child(2):after {
                    display: none;
                }
            }
        }
    }
    @media (max-width: 500px) {
        width: calc(100% - 60px);
        padding: 15px 25px !important;
        overflow: auto;
        max-height: 80vh;
        h5 {
            font-size: 16px;
            line-height: 22px;
        }
        ul {
            li {
                font-size: 12px;
            }
            &:after {
                bottom: -40px;
            }
            &+div b {
                font-size: 18px;
            }
        }
        .modal-pluses {
            p {
                font-size: 16px;
                font-weight: 700;
            }
            .modal-pluses__content {
                margin-top: 15px;
                display: block;
                div {
                    font-size: 12px;
                    line-height: 16px;
                    text-align: center;
                    max-width: 100%;
                    margin-bottom: 10px;
                }
            }
        }
        .modal-close {
            top: 10px;
            right: 10px;
        }
    }
}

.ReactModal__Content {
    outline: none;
    &:active,
    &:focus {
        outline: none;
        border: none;
    }
}

.ReactModal__Content {
    overflow: visible;
    margin-top: 30px;
    margin-bottom: 30px;
    max-height: calc(100% - 15px);
}

.user {
    margin-bottom: 20px;
    margin-top: -10px;
    font-weight: bold;
    b {
        font-weight: bold;
    }
}

.ReactModal__Overlay {
    overflow-y: auto;
    padding: 30px 0px;
}

.carousel-modal {
    @media (max-height: 850px) {
        .modal-close {
            top: 35px;
        }
    }
}

.Toastify {
    position: absolute;
}

.product__info {
    position: relative;
    z-index: 40;
    @media (max-width: 768px) {
        overflow-y: hidden;
    }
}

.product__info .countdown {
    font-size: 18px;
    .countdown__date {
        color: #e60e27;
        font-weight: 600;
        display: inline-flex;
        i {
            animation: pulse 1s infinite;
            display: block;
            margin-left: 3px;
            margin-right: 3px;
        }
    }
    @media (max-width: 576px) {
        font-size: 14px;
        white-space: nowrap;
    }
}

@keyframes pulse {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.2;
    }
    100% {
        opacity: 1;
    }
}

main .product .product__banner .product__view {
    @media (max-width: 767px) {
        pointer-events: all;
    }
}