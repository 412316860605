@import "../../../assets/css/variables/colors";
@import "../../../assets/css/mixins/flex";
header {
    position: relative;
    // top: 0;
    // left: 0;
    // width: 100%;
    z-index: 90;
    background: transparent;
    // box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    .header__inner {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 84px;
        .header__end {
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }
        .header__bucket {
            background: transparent;
            border: none;
            display: flex;
            align-items: center;
            position: relative;
            justify-content: center;
            font-size: 24px;
            line-height: 28px;
            span {
                @media (max-width: 767px) {
                    display: none;
                }
            }
            img {
                margin-left: 13px;
                width: 50px;
            }
            @media (max-width: 767px) {
                img {
                    width: 40px;
                }
            }
            .header__bucket-counter {
                color: #fff;
                position: absolute;
                right: -5px;
                top: -5px;
                font-weight: 700;
                font-size: 12px;
                border: 2px solid #fff;
                background: #8699a3;
                width: 16px;
                height: 16px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
            }
        }
        @media (min-width: 991px) {
            .socials {
                display: none;
            }
        }
    }
    @media (max-width: 767px) {
        .header__inner {
            height: 64px;
        }
        .header__logo {
            width: 95px;
            height: 24px;
        }
        .header__bucket {
            margin: 0;
            padding: 0;
        }
        .socials {
            margin-right: 0;
        }
    }
}

* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.socials {
    display: flex;
    align-items: center;
    justify-content: ƒlex-start;
    margin-right: 28px;
}

.header__logo_desktop {
    @media (max-width: 767px) {
        display: none;
    }
}

.header__logo_mobile {
    @media (min-width: 768px) {
        display: none;
    }
}

.bucket__preview {
    position: absolute;
    background: #fff;
    top: 100%;
    right: 0;
    z-index: 0;
    padding: 20px 25px;
    border: 1px solid #e0e0e0;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    box-shadow: 0px 4px 38px -12px rgba(0, 0, 0, 0.18), 0px 8px 96px -12px rgba(34, 42, 46, 0.32);
    opacity: 0;
    pointer-events: none;
    transition: 0.2s ease-in-out;
    &.active {
        opacity: 1;
        pointer-events: all;
        transition: 0.2s ease-in-out;
    }
    .bucket__preview-item {
        display: flex;
        align-items: center;
    }
    b {
        font-weight: 700;
    }
    img {
        width: 50px;
        margin-right: 20px;
    }
    .counter {
        margin-left: 40px;
        margin-right: 40px;
        min-height: 40px;
        max-height: 40px;
        span {
            font-size: 18px;
            color: #8699a3;
        }
        button {
            font-size: 18px;
            max-width: 30px;
            min-width: 30px;
        }
    }
    p {
        color: #e60e27;
        text-decoration: underline;
        cursor: pointer;
        // margin-left: 30px;
        margin-top: 20px;
        &:hover {
            text-decoration: none;
        }
    }
    @media (max-width: 600px) {
        width: calc(100% - 40px);
        padding: 20px;
        .bucket__preview-item {
            width: 100%;
            justify-content: space-between;
        }
        .counter {
            min-height: 30px;
            max-height: 30px;
            margin-left: 15px;
            margin-right: 15px;
            span {
                font-size: 14px;
                width: 30px;
            }
            button {
                font-size: 14px;
                min-width: 20px;
                max-width: 20px;
            }
        }
    }
    @media (max-width: 400px) {
        .bucket__preview-item {
            img {
                display: none;
            }
        }
    }
}