@import "../../../node_modules/reset-css/reset.css";
@import "./variables/screen";
@import "./variables/main";
@import "./variables/colors";
@import "./mixins/flex";
@import "./components/container";
@import "./components/btn";
@import "./components/link";
@import url("https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;700&display=swap");
* {
    font-family: "PT Sans", sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 700;
}